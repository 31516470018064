import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { getFirestore, collection, query, where, getDocs, orderBy } from 'firebase/firestore';

interface Order {
  date: string;
  orderId: string; 
  address: string;
  recipient: string;
  paymentMethod: string;
  itemsTotal: number;
  shippingCost: number;
  orderTotal: number;
  items: CartItem[];
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  size: string;
  image: string;
}

interface MeuspedidosPageProps {
    handleShowCart: () => void;
    updateCartDetails: () => void;
}

const MeuspedidosPage: React.FC<MeuspedidosPageProps> = ({ handleShowCart, updateCartDetails }) => {

  const [orders, setOrders] = useState<Order[]>([]);
  const [mensagem, setMensagem] = useState<string | null>(null);

  useEffect(() => {
    const fetchPedidos = async () => {
      const db = getFirestore();
      const storedEmail = localStorage.getItem('merthiolateUserEmail');

      if (storedEmail) {
        const pedidosRef = collection(db, 'pedido');
        const q = query(
          pedidosRef,
          where('dadosPessoais.email', '==', storedEmail),
          orderBy('dataPedido', 'desc')
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const fetchedOrders: Order[] = querySnapshot.docs.map((doc) => {
            const pedidoData = doc.data();
            return {
              date: new Date(pedidoData.dataPedido.seconds * 1000).toLocaleDateString('pt-BR'), // Converte o timestamp para data legível
              orderId: doc.id,
              address: `${pedidoData.enderecoEntrega.logradouro}, ${pedidoData.enderecoEntrega.numero}, ${pedidoData.enderecoEntrega.bairro}, ${pedidoData.enderecoEntrega.cidade} / ${pedidoData.enderecoEntrega.estado}`,
              recipient: pedidoData.dadosPessoais.nome,
              paymentMethod: pedidoData.formaPagamento,
              itemsTotal: pedidoData.valorTotalItens,
              shippingCost: pedidoData.valorFrete,
              orderTotal: pedidoData.valorTotalCompra,
              items: pedidoData.itens
            };
          });
          setOrders(fetchedOrders);
          setMensagem(null);
        } else {
          setMensagem('Nenhum pedido encontrado.');
        }
      } else {
        setMensagem('Nenhum pedido encontrado.');
      }
    };

    fetchPedidos();
  }, []);

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <>
      <Container className='mt-5'>
        <Row>
          <h4 className='text-center pb-4'>Meus pedidos</h4>
          {mensagem ? (
            <h5 className='text-center'>{mensagem}</h5>
          ) : (
            <Col lg={12}>
              <Accordion defaultActiveKey={['0']}>
                {orders.map((order, index) => (
                  <Accordion.Item eventKey={String(index)} key={order.orderId}>
                    <Accordion.Header>Pedido {order.date}</Accordion.Header>
                    <Accordion.Body>
                      <Row className='mb-3'>
                        <Col lg={6} className='text-start'>
                          <h6>Código do pedido: <b>{order.orderId}</b></h6>
                          <h6>Data do pedido: <b>{order.date}</b></h6>
                          <h6>Endereço de entrega:</h6>
                          <h6><b>{order.address}</b></h6>
                          <h6>Nome do destinatário: <b>{order.recipient}</b></h6>
                          <h6>Forma de pagamento: <b>{order.paymentMethod}</b></h6>
                          <h6>Valor total dos itens: <b>{formatCurrencyBRL(order.itemsTotal)}</b></h6>
                          <h6>Valor do frete: <b>{formatCurrencyBRL(order.shippingCost)}</b></h6>
                          <h6>Valor total do pedido: <b>{formatCurrencyBRL(order.orderTotal)}</b></h6>
                        </Col>
                        <Col lg={6}>
                          {order.items.map((item) => (
                            <Row className='mb-3' key={item.id}>
                              <Col lg={2}>
                                <img src={item.image} alt={item.name} style={{ width: '100%' }} />
                              </Col>
                              <Col lg={10} className='text-start'>
                                <h6 className='fw-300 mb-1'>Nome do produto: <b>{item.name}</b></h6>
                                <h6 className='fw-300 mb-1'>Tamanho: <b>{item.size}</b></h6>
                                <h6 className='fw-300 mb-1'>Quantidade: <b>{item.quantity}</b></h6>
                                <h6><b>{formatCurrencyBRL(item.price * item.quantity)}</b></h6>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default MeuspedidosPage;