import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const FooterComponent: React.FC = () => {
  return (
    <footer className="bg-white text-dark pb-1 pt-3" style={{borderTop:'1px solid #ccc'}}>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={11} className="text-center text-md-right mt-1 mt-md-0">
            <p className='text-center'><small>LOJA MERTHIOLATE - CNPJ: 68.542.901/0001-49<br />Todos os direitos reservados © {new Date().getFullYear()}</small></p>
          </Col>
          <Col xs={12} md={1} className="text-center text-md-left">
            <img 
              src="/assets/logo-merthiolate-1.jpg" 
              alt="Merthiolate Logo" 
              style={{ maxWidth: '150px' }} 
            />
          </Col>
        
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;