import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FooterComponent from '../components/FooterComponent';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { InputGroup, Form } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AwesomeSlider from 'react-awesome-slider';
import Slider from "react-slick";
import JsonLdComponent from '../components/JsonLdComponent';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

type ItemData = {
  id: string;
  nome: string;
  link: string;
  valor: number;
  imagem: string[];
  categoria: string;
  subcategoria: string;
  tamanho: string[];
  cores: { nomeCor: string; codigoCor: string }[];
};

const DetalhePage: React.FC<{ handleShowCart: () => void; updateCartDetails: () => void }> = ({ handleShowCart, updateCartDetails }) => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { link } = useParams<{ link: string }>();

  const handleCorClick = (cor: string) => setCorSelecionada(cor);

  const [itemData, setItemData] = useState<ItemData[] | null>(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState<string | null>(null);
  const [corSelecionada, setCorSelecionada] = useState<string | null>(null);
  const [itemValue, setItemValue] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [preco, setPreco] = useState('');
  const [dataSchema, setDataSchema] = useState<Record<string, any> | null>(null);

  const increment = () => setQuantity(prev => prev + 1);
  const decrement = () => setQuantity(prev => (prev > 1 ? prev - 1 : 1));
  
  const addToCart = () => {

    let adicionaItemCarrinho = true;

    if(tamanhoSelecionado === null){

      adicionaItemCarrinho = false;

      toast.warning(<div dangerouslySetInnerHTML={{ __html: 'Selecione um tamanho' }} />);
    }

    if(corSelecionada === null){

      adicionaItemCarrinho = false;

      toast.warning(<div dangerouslySetInnerHTML={{ __html: 'Selecione uma cor' }} />);
    }

    if(adicionaItemCarrinho === true){
      
      if (itemData && itemData.length > 0) {

        const cartItem = {
          id: itemData[0].id + "_" + tamanhoSelecionado + "_" + corSelecionada,
          name: itemData[0].nome,
          price: itemValue,
          quantity: quantity,
          sumPriceItem: itemValue * quantity,
          size: tamanhoSelecionado,
          image: selectedImage,
          color: corSelecionada
        };

        const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
        
        const itemExists = existingCart.some((item: any) => item.id === cartItem.id);

        if (itemExists) {
          
          toast.info('Este item já foi adicionado ao carrinho.');
        } else {
         
          const updatedCart = [...existingCart, cartItem];
          localStorage.setItem('cart', JSON.stringify(updatedCart));
  
          updateCartDetails();
          toast.success('Item adicionado ao carrinho com sucesso!');

          const eventData = {
            currency: "BRL",
            value: cartItem.sumPriceItem
          };
        
          sendConversionEvent('AddToCart', eventData);
        }

        handleShowCart();
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const products = Array.from({ length: 2 }, (_, index) => index);
  
  const handleTamanhoClick = (tamanho:any) => setTamanhoSelecionado(tamanho);
  
  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  
  const removeFromCart = (id: number) => {
    const updatedCart = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  
  const sendConversionEvent = async (eventName:any, eventData:any) => {
    const accessToken = 'EAAIExRSv1WIBO5T96HjzXU6MIXK6WjV0NLVKCd7IbbWVOO2xJDGONCQcQxwbkOL8rWf7SzZBfoHNbWUz2g2Cn8eU0FYIn5QLSp6PoZCZC2vzywKoXMnKsj5YX32UHMms1NBIJMp5DXOnQ3dSMSayMMviGKizyidTcciQwjmLJArkEmv3YJ0uzjZC8OQ9etCmZBQZDZD';
    const pixelId = '2080943705684360';  // Substitua pelo ID real do seu Pixel
    const apiVersion = 'v12.0';       // Atualize a versão da API conforme necessário
  
    const payload = {
      data: [
        {
          event_name: eventName,
          event_time: Math.floor(new Date().getTime() / 1000), // Timestamp atual
          action_source: 'website',
          user_data: {
            em: [
              "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
            ],
            ph: [
              null
            ]
          },
          custom_data: eventData,
          original_event_data: {
            event_name: eventName,
            event_time: Math.floor(new Date().getTime() / 1000)
          }
        }
      ]
    };
  
    const url = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Evento enviado com sucesso:', result);
      } else {
        console.error('Erro ao enviar evento:', result);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartItems(storedCart);
  }, [show]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'item'), where('link', '==', link));
  
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map(doc => {
        const data = doc.data() as ItemData;
        const itemId = doc.id;
  
        setItemValue(data.valor);
        setPreco(formatCurrencyBRL(data.valor * quantity));
  
        if (data.imagem && data.imagem.length > 0) {
          setSelectedImage(`/assets/${data.link}/${data.imagem[0]}`);
        } else {
          setSelectedImage('/assets/imagem-indisponivel.jpg');
        }

        const rootDomain = window.location.origin;

        setDataSchema ({
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": data.nome,
          "image": [
            rootDomain+'/assets/'+data.link+'/1.jpg'
          ],
          "description": data.nome,
          "sku": doc.id,
          "offers": {
            "@type": "Offer",
            "url": rootDomain+'/'+data.link,
            "priceCurrency": "BRL",
            "price": data.valor,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "priceValidUntil": `${new Date().getFullYear()}-12-31`
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.5",  
            "reviewCount": "30"  
          },
          "review": [
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "Rubens Matos"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5"
            },
            "reviewBody": "Ótimo produto! Recomendo.",
            "datePublished": new Date().toISOString().split('T')[0]
          }]
        });

        const eventData = {
          content_name: data.link,
          content_category: data.categoria+' '+data.subcategoria,
          content_ids: [doc.id],
          currency: "BRL",
          value: data.valor 
        };

        sendConversionEvent('ViewContent', eventData);
      
        return {
          id: doc.id,
          nome: data.nome || '',
          link: data.link || '',
          valor: data.valor || 0,
          imagem: data.imagem || [],  
          tamanho: data.tamanho || [],
          cores: data.cores || [],
          categoria: data.categoria || '',
          subcategoria: data.subcategoria || ''  
        };
      });

      setItemData(items);

      if (items.length > 0 && items[0].cores.length > 0) {
        
        handleCorClick(items[0].cores[0].codigoCor);
      }
    };
    
    fetchData();
  }, [link, quantity]);

  const [cartItems, setCartItems] = useState<any[]>([]);

  if (!itemData) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <ToastContainer position='top-center' pauseOnHover hideProgressBar={true} closeOnClick />
      <Container className='mt-5 mt-2-mob'>
    <Row>
    <>
    {dataSchema && <JsonLdComponent data={dataSchema} />}
    <Col lg={12} className='text-center pb-3'>
      <h2 className='mb-0 text-center px-5'>{itemData[0].nome}</h2>
    </Col>

    <Col lg={5} className='mb-5'>
    
    <AwesomeSlider animation="foldOutAnimation" organicArrows={true} style={{ width: '100%', height: '500px' }} className='mb-3'>
      {itemData[0].imagem.map((img, index) => {
        const imagePath = `/assets/${itemData[0].link}/${img}`;
        return (
          <div key={index} style={{ display: 'flex' }}>
            <img
              src={imagePath}
              alt={`Imagem ${index + 1}`}
              style={{ maxWidth: '100%', height: '500px' }}
            />
          </div>
        );
      })}
    </AwesomeSlider>
    </Col>    

    <Col lg={7} className='mb-3'>
    <div className='my-1 d-block px-1 float-start cemPor'>
    <h5 className='text-start mt-3'>Selecione a cor</h5>
    {itemData[0]?.cores?.length > 0 && (
      <div className='mb-3 mt-1 d-block px-1 float-start cemPor'>
        {itemData[0].cores.map((cor, index) => (
          <span
            key={index}
            className={`circuloCor margin-auto d-block float-start ${corSelecionada === cor.codigoCor ? 'cor-selecionada' : ''}`}
            style={{ backgroundColor: cor.codigoCor, cursor: 'pointer' }}
            onClick={() => handleCorClick(cor.codigoCor)}
          >
          </span>
        ))}
      </div>
    )}
    </div>
    <div className='my-1 px-1 float-start cemPor'>
    <h5 className='text-start mt-0'>Selecione o tamanho</h5>
    <div>
    {itemData[0].tamanho.map((tam, index) => (
      <span
      key={tam}
      className={`quadrado-tamanho margin-auto d-block l-h-tamanho float-start br-1 ${tamanhoSelecionado === tam ? 'quadrado-tamanho-sel' : ''}`}
      onClick={() => handleTamanhoClick(tam)}
      >
      {tam}
      </span>
    ))}
    </div>
    </div>       
    <div className='my-1 px-1 float-start cemPor'>
    <h5 className='text-start mt-3'>Quantidade</h5>
    <div className='d-flex align-items-center mt-2 br-1' style={{border:"1px solid #999",float:"left"}}>
    <Button variant="none" className='px-3 py-2 br-2' onClick={decrement}>
    <FaMinus />
    </Button>
    <div
    className='mx-2 text-center d-flex justify-content-center align-items-center'
    style={{ width: '80px', height: '40px', fontSize:'1.6em' }}
    >
    {quantity}
    </div>
    <Button variant="none" className='px-3 py-2 br-2' onClick={increment}>
    <FaPlus />
    </Button>
    </div>       
    <h2 className='mb-1 pt-4 text-start cemPor float-start'>{preco}</h2>
    <button className='btn btn-danger btn-lg float-start mt-3 br-1' style={{minWidth:"300px"}} onClick={addToCart}>ADICIONAR A SACOLA</button>    
    </div>
    </Col>
    </>
    </Row>
    </Container>
    <FooterComponent />
    </>
  );
};

export default DetalhePage;