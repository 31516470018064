import React, { useEffect, useState, Suspense } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import LazyLoad from 'react-lazyload'; // Para lazy loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import JsonLdConpoment from '../components/JsonLdComponent'; // Lazy load do componente JSON-LD
import FooterComponent from '../components/FooterComponent';
import { MdDoubleArrow } from "react-icons/md";

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC43UVEvhP7_YifNb37YifNb37XzNde19v-6pBNDs",
  authDomain: "merthiolate-537c1.firebaseapp.com",
  projectId: "merthiolate-537c1",
  storageBucket: "merthiolate-537c1.appspot.com",
  messagingSenderId: "1020805118239",
  appId: "1:1020805118239:web:fb59eb9c5d45fdfb67d82a",
  measurementId: "G-KBHCR052WV"
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const analytics = getAnalytics(app);
export const db = getFirestore(app);

const LOCAL_STORAGE_KEY_ITEMS = 'homePageItems';
const LOCAL_STORAGE_KEY_CATEGORIES = 'homePageCategories';
const EXPIRATION_TIME = 3 * 60 * 60 * 1000; // 3 horas em milissegundos

const HomePage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {
  const [todosData, setTodosData] = useState<{ id: string; link: string; nome: string; valor: number; imagem: string[]; tamanho: string[]; categoria: string; subcategoria: string }[]>([]);
  const [categoriesData, setCategoriesData] = useState<Record<string, string[]>>({});
  const [dataSchema, setDataSchema] = useState<Record<string, any> | null>(null);

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'item'));
      const items = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          nome: data.nome || '',
          link: data.link || '',
          valor: data.valor || 0,
          imagem: data.imagem || [],
          tamanho: data.tamanho || [],
          categoria: data.categoria || '',
          subcategoria: data.subcategoria || ''
        };
      });

      const categories = {
        "moda feminina": [
          "conjuntos",
          "tops",
          "shorts",
          "calcas",
          "corta ventos",
          "bermudas"
        ],
        "moda masculina": [
          "camisetas",
          "regatas",
          "conjuntos"
        ]
      };

      localStorage.setItem(LOCAL_STORAGE_KEY_ITEMS, JSON.stringify({
        items,
        timestamp: Date.now()
      }));
      localStorage.setItem(LOCAL_STORAGE_KEY_CATEGORIES, JSON.stringify({
        categories,
        timestamp: Date.now()
      }));

      setTodosData(items);
      setCategoriesData(categories);
    } catch (error) {
      console.error("Erro ao buscar dados do Firestore:", error);
    }
  };

  const loadDataFromLocalStorage = () => {
    try {
      const storedItems = localStorage.getItem(LOCAL_STORAGE_KEY_ITEMS);
      const storedCategories = localStorage.getItem(LOCAL_STORAGE_KEY_CATEGORIES);
      const currentTime = Date.now();

      const itemsValid = storedItems ? JSON.parse(storedItems) : null;
      const categoriesValid = storedCategories ? JSON.parse(storedCategories) : null;

      if (itemsValid && currentTime - itemsValid.timestamp < EXPIRATION_TIME) {
        setTodosData(itemsValid.items);
      } else {
        return false;
      }

      if (categoriesValid && currentTime - categoriesValid.timestamp < EXPIRATION_TIME) {
        setCategoriesData(categoriesValid.categories);
      } else {
        return false;
      }

      return true;
    } catch (error) {
      console.error("Erro ao acessar o LocalStorage:", error);
      return false;
    }
  };

  useEffect(() => {
    if (!loadDataFromLocalStorage()) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (todosData.length > 0) {
      const rootDomain = window.location.origin;

      const productListSchema = {
        "@context": "https://schema.org/",
        "@type": "ItemList",
        "itemListElement": todosData.map((item, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@type": "Product",
            "name": item.nome,
            "image": [`${rootDomain}/assets/${item.link}/1.jpg`],
            "description": item.nome,
            "sku": item.id,
            "offers": {
              "@type": "Offer",
              "url": `${rootDomain}/${item.link}`,
              "priceCurrency": "BRL",
              "price": item.valor,
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }
        }))
      };

      setDataSchema(productListSchema);
    }
  }, [todosData]);

  const normalizeCategory = (category: string) => {
    return category.replace(/\s+/g, '-').toLowerCase();
  };

  const normalizeStringForURL = (text: string) => {
    return text.trim().toLowerCase().replace(/\s+/g, '-');
  };

  const handleImageClick = (link: string) => {
    window.location.href = `/${link}`;
  };

  return (
    <>
      <Container className='mt-1 mt-2-mob'>
        {Object.keys(categoriesData).map((categoria) => (
          <div key={categoria}>
            <h5 className='text-start pt-1 pb-1'>{categoria.replace("moda", "Moda")}</h5>
            {categoriesData[categoria].map((subcategoria: string) => (
              <div key={subcategoria}>
                <h6 className='text-start pb-1 text-capitalize' style={{fontWeight:"bold"}}>{subcategoria}</h6>
                <Row>
                  {todosData
                    .filter(item => {
                      const normalizedCategory = normalizeCategory(categoria);
                      const normalizedSubcategory = normalizeCategory(subcategoria);
                      return item.categoria === normalizedCategory && item.subcategoria === normalizedSubcategory;
                    })
                    .slice(0, 4)
                    .map(item => (
                      <Col lg={3} className='mb-2' key={item.id}>
                        <Card className='card-sombra'>
                        <Card.Body className="p-1">
                          <AwesomeSlider style={{ width: "100%", height: "400px" }} className="mb-5">
                            {item.imagem.map((img: string, index: number) => {
                              const imagePath = `assets/${item.link}/${img}`;

                              return (
                                <LazyLoad key={index} height={400} offset={100}>
                                  <LazyLoadImage
                                    alt={item.nome}
                                    effect="blur"
                                    src={imagePath}
                                    width="100%"
                                    height="400px"
                                    style={{ objectFit: "cover", cursor: "pointer" }}
                                    onClick={() => handleImageClick(item.link)}
                                  />
                                </LazyLoad>
                              );
                            })}
                          </AwesomeSlider>

                          <h6 className="mb-1 px-1 d-flex align-items-center justify-content-center" style={{ minHeight: "65px" }}>
                            {item.nome}
                          </h6>
                          <h4 className="mb-2">{formatCurrencyBRL(item.valor)}</h4>

                          <div className="p-2 cemPor">
                            <Button
                              variant="danger"
                              className="btn-block cemPor br-1"
                              onClick={() => handleImageClick(item.link)}
                            >
                              COMPRAR
                            </Button>
                          </div>
                        </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>
                <h4 className='text-start pb-3 px-1 text-capitalize'><Link to={'/filtro?categoria='+normalizeStringForURL(categoria)+'&subcategoria='+normalizeStringForURL(subcategoria)} style={{textDecoration:'none'}}><span><small className='text-dark' style={{fontSize:"0.6em",textDecoration:"none"}}>MAIS OPÇÕES <MdDoubleArrow className='text-dark' />
                </small></span></Link></h4>
              </div>
            ))}
          </div>
        ))}
      </Container>

      <Suspense fallback={<div>Loading...</div>}>
        {dataSchema && <JsonLdConpoment data={dataSchema} />}
      </Suspense>
      <FooterComponent />
    </>
  );
};

export default HomePage;