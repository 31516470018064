import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'react-awesome-slider/dist/styles.css';
import { InputGroup, Form } from 'react-bootstrap';
import { FaPix, FaRegCreditCard, FaMinus, FaPlus } from 'react-icons/fa6';
import { FaCreditCard, FaRegTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';
import FooterComponent from '../components/FooterComponent';
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
  apiKey: "AIzaSyC43UVEvhP7_YifNb37XzNde19v-6pBNDs",
  authDomain: "merthiolate-537c1.firebaseapp.com",
  projectId: "merthiolate-537c1",
  storageBucket: "merthiolate-537c1.appspot.com",
  messagingSenderId: "1020805118239",
  appId: "1:1020805118239:web:fb59eb9c5d45fdfb67d82a",
  measurementId: "G-KBHCR052WV"
};

let app;

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const analytics = getAnalytics(app);
export const db = getFirestore(app);

interface Frete {
  id: number;
  name: string;
  price: number;
  delivery_time: number;
  currency: string;
  company: {
    name: string;
    picture: string;
  };
}

interface CartItem {
    id: string;
    name: string;
    price: number;
    quantity: number;
    size: string;
    image: string;
    color: string;
}

interface AlertProps {
  id: number;
  type: 'info' | 'success' | 'warning' | 'danger';
  message: string;
}

const ResumoPage: React.FC<{ handleShowCart: () => void; updateCartDetails: () => void }> = ({ handleShowCart, updateCartDetails }) => {
  
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertProps[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPedido, setTotalPedido] = useState<number>(totalPrice);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [fretes, setFretes] = useState<Frete[]>([]);
  const [freteSelecionado, setFreteSelecionado] = useState<Frete | null>(null);
  const [cep, setCep] = useState('');
  
  const calcularTotalComFrete = (totalItemsPrice: number, frete: Frete | null) => {
    return frete ? totalItemsPrice + frete.price : totalItemsPrice;
  };  

  const [endereco, setEndereco] = useState({
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: '',
    complemento: '',
    destinatario: ''
  });

  const [dadosPessoais, setDadosPessoais] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    telefone: ''
  });

  const [erro, setErro] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);

  const handleEnderecoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEndereco(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const addWarningClass = (id:any) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.add('border-warning');
    }
  };

  const removeWarningClass = (id:any) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.remove('border-warning');
    }
  };

  const validarCPF = (cpf:any) => {
    
    cpf = cpf.replace(/[^\d]+/g, '');
    
    if (cpf.length !== 11) {
      return false;
    }
  
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
  
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let primeiroDigitoVerificador = 11 - (soma % 11);
    if (primeiroDigitoVerificador >= 10) {
      primeiroDigitoVerificador = 0;
    }
    if (primeiroDigitoVerificador !== parseInt(cpf.charAt(9))) {
      return false;
    }
  
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let segundoDigitoVerificador = 11 - (soma % 11);
    if (segundoDigitoVerificador >= 10) {
      segundoDigitoVerificador = 0;
    }
    if (segundoDigitoVerificador !== parseInt(cpf.charAt(10))) {
      return false;
    }
  
    return true;
  };

  const gerarPedido = async () => {
    let textoAlerta = '';
  
    if (!dadosPessoais.nome) {
      textoAlerta += 'Preencha o <b>Nome</b> <br />';
      addWarningClass('nome');
    } else {
      removeWarningClass('nome');
    }

    if (!dadosPessoais.sobrenome) {
      textoAlerta += 'Preencha o <b>Sobrenome</b> <br />';
      addWarningClass('sobrenome');
    } else {
      removeWarningClass('sobrenome');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!dadosPessoais.email || !emailRegex.test(dadosPessoais.email)) {
      textoAlerta += 'Preencha um <b>E-mail</b> válido <br />';
      addWarningClass('email');
    } else {
      removeWarningClass('email');
    }

    if (!dadosPessoais.cpf) {
      textoAlerta += 'Preencha o <b>CPF</b> <br />';
      addWarningClass('cpf');
    } else if (!validarCPF(dadosPessoais.cpf)) {
      textoAlerta += 'O <b>CPF</b> informado é inválido <br />';
      addWarningClass('cpf');
    } else {
      removeWarningClass('cpf');
    }

    if (!dadosPessoais.telefone) {
      textoAlerta += 'Preencha o <b>Telefone</b> <br />';
      addWarningClass('telefone');
    } else {
      removeWarningClass('telefone');
    }

    if (!endereco.logradouro) {
      textoAlerta += 'Preencha o <b>Logradouro</b> <br />';
      addWarningClass('logradouro');
    } else {
      removeWarningClass('logradouro');
    }

    if (!endereco.numero) {
      textoAlerta += 'Preencha o <b>Número</b> <br />';
      addWarningClass('numero');
    } else {
      removeWarningClass('numero');
    }
    
    if (!endereco.bairro) {
      textoAlerta += 'Preencha o <b>Bairro</b> <br />';
      addWarningClass('bairro');
    } else {
      removeWarningClass('bairro');
    }

    if (!endereco.cidade) {
      textoAlerta += 'Preencha a <b>Cidade</b> <br />';
      addWarningClass('cidade');
    } else {
      removeWarningClass('cidade');
    }

    if (!endereco.estado) {
      textoAlerta += 'Preencha o <b>Estado</b> <br />';
      addWarningClass('estado');
    } else {
      removeWarningClass('estado');
    }

    if (!endereco.cep) {
      textoAlerta += 'Preencha o <b>CEP</b> <br />';
      addWarningClass('cep');
    } else {
      removeWarningClass('cep');
    }

    if (!endereco.destinatario) {
      textoAlerta += 'Digite no nome do <b>Destinatário</b> <br />';
      addWarningClass('destinatario');
    } else {
      removeWarningClass('destinatario');
    }

    if (freteSelecionado === null) {
      textoAlerta += 'Selecione o <b>Frete</b> <br />';
    }
  
    if (textoAlerta !== '') {

      toast.dismiss();
      toast.warning(<div dangerouslySetInnerHTML={{ __html: textoAlerta }} />);
    } else {

      const storedEmail = localStorage.getItem('merthiolateUserEmail');

      if (storedEmail) {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      } else {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      }
  
      const pedido = {
        dadosPessoais: {
          nome: dadosPessoais.nome,
          sobrenome: dadosPessoais.sobrenome,
          email: dadosPessoais.email,
          cpf: dadosPessoais.cpf,
          telefone: dadosPessoais.telefone,
        },
        enderecoEntrega: {
          cep: endereco.cep,
          logradouro: endereco.logradouro,
          numero: endereco.numero,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          destinatario: endereco.destinatario,
          complemento: endereco.complemento,
        },
        freteSelecionado: {
          id: freteSelecionado?.id,
          name: freteSelecionado?.name,
          price: freteSelecionado?.price,
          delivery_time: freteSelecionado?.delivery_time,
        },
        itens: cartItems.map(item => ({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          size: item.size,
          image: item.image,
          color: item.color,
        })),
        valorTotalCompra: totalPedido,
        dataPedido: Timestamp.now(),
        statusPedido: 'Aberto',
      };
  
      try {

        const docRef = await addDoc(collection(db, 'pedido'), pedido);
        navigate('/checkout');

      } catch (error) {
        
        toast.error('Erro ao realizar o pedido. Tente novamente.');
      }
    }
  };  

  const sendConversionEvent = async (eventName:any, eventData:any) => {
    const accessToken = 'EAAIExRSv1WIBO5T96HjzXU6MIXK6WjV0NLVKCd7IbbWVOO2xJDGONCQcQxwbkOL8rWf7SzZBfoHNbWUz2g2Cn8eU0FYIn5QLSp6PoZCZC2vzywKoXMnKsj5YX32UHMms1NBIJMp5DXOnQ3dSMSayMMviGKizyidTcciQwjmLJArkEmv3YJ0uzjZC8OQ9etCmZBQZDZD';
    const pixelId = '2080943705684360';  // Substitua pelo ID real do seu Pixel
    const apiVersion = 'v12.0';       // Atualize a versão da API conforme necessário
  
    const payload = {
      data: [
        {
          event_name: eventName,
          event_time: Math.floor(new Date().getTime() / 1000), // Timestamp atual
          action_source: 'website',
          user_data: {
            em: [
              "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
            ],
            ph: [
              null
            ]
          },
          custom_data: eventData,
          original_event_data: {
            event_name: eventName,
            event_time: Math.floor(new Date().getTime() / 1000)
          }
        }
      ]
    };
  
    const url = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Evento enviado com sucesso:', result);
      } else {
        console.error('Erro ao enviar evento:', result);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  const gerarPedidoPix = async () => {
    let textoAlerta = '';
  
    if (!endereco.logradouro) {
      textoAlerta += 'Preencha o <b>Logradouro</b> <br />';
      addWarningClass('logradouro');
    } else {
      removeWarningClass('logradouro');
    }

    if (!endereco.numero) {
      textoAlerta += 'Preencha o <b>Número</b> <br />';
      addWarningClass('numero');
    } else {
      removeWarningClass('numero');
    }
    
    if (!endereco.bairro) {
      textoAlerta += 'Preencha o <b>Bairro</b> <br />';
      addWarningClass('bairro');
    } else {
      removeWarningClass('bairro');
    }

    if (!endereco.cidade) {
      textoAlerta += 'Preencha a <b>Cidade</b> <br />';
      addWarningClass('cidade');
    } else {
      removeWarningClass('cidade');
    }

    if (!endereco.estado) {
      textoAlerta += 'Preencha o <b>Estado</b> <br />';
      addWarningClass('estado');
    } else {
      removeWarningClass('estado');
    }

    if (!endereco.cep) {
      textoAlerta += 'Preencha o <b>CEP</b> <br />';
      addWarningClass('cep');
    } else {
      removeWarningClass('cep');
    }

    if (freteSelecionado === null) {
      textoAlerta += 'Selecione o <b>Frete</b> <br />';
    }
  
    if (textoAlerta !== '') {

      toast.dismiss();
      toast.warning(<div dangerouslySetInnerHTML={{ __html: textoAlerta }} />);
    } else {

      const storedEmail = localStorage.getItem('merthiolateUserEmail');

      if (storedEmail) {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      } else {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      }
  
      const pedido = {
        enderecoEntrega: {
          cep: endereco.cep,
          logradouro: endereco.logradouro,
          numero: endereco.numero,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          destinatario: endereco.destinatario,
          complemento: endereco.complemento,
        },
        freteSelecionado: {
          id: freteSelecionado?.id,
          name: freteSelecionado?.name,
          price: freteSelecionado?.price,
          delivery_time: freteSelecionado?.delivery_time,
        },
        itens: cartItems.map(item => ({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          size: item.size,
          image: item.image,
          color: item.color,
        })),
        valorTotalCompra: totalPedido,
        dataPedido: Timestamp.now()
      };
  
      try {

        const pedidoResponse = await fetch(`${process.env.REACT_APP_API_URL}/asaas/gerarlinkpagamentopix`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            pedido: pedido
          }),
          mode: 'cors'
        });     
        
        const result = await pedidoResponse.json();

        if(result){
        
          if(result['url']){

            const eventData = {
              currency: "BRL",
              value: totalPedido,
            };
          
            sendConversionEvent('InitiateCheckout', eventData);

            //window.location.href = result['url'];
          }
        }
      } catch (error) {
        
        toast.error('Erro ao realizar o pedido. Tente novamente.');
      }
    }
  };  

  const gerarPedidoCartao = async () => {
    let textoAlerta = '';
  
    if (!endereco.logradouro) {
      textoAlerta += 'Preencha o <b>Logradouro</b> <br />';
      addWarningClass('logradouro');
    } else {
      removeWarningClass('logradouro');
    }

    if (!endereco.numero) {
      textoAlerta += 'Preencha o <b>Número</b> <br />';
      addWarningClass('numero');
    } else {
      removeWarningClass('numero');
    }
    
    if (!endereco.bairro) {
      textoAlerta += 'Preencha o <b>Bairro</b> <br />';
      addWarningClass('bairro');
    } else {
      removeWarningClass('bairro');
    }

    if (!endereco.cidade) {
      textoAlerta += 'Preencha a <b>Cidade</b> <br />';
      addWarningClass('cidade');
    } else {
      removeWarningClass('cidade');
    }

    if (!endereco.estado) {
      textoAlerta += 'Preencha o <b>Estado</b> <br />';
      addWarningClass('estado');
    } else {
      removeWarningClass('estado');
    }

    if (!endereco.cep) {
      textoAlerta += 'Preencha o <b>CEP</b> <br />';
      addWarningClass('cep');
    } else {
      removeWarningClass('cep');
    }

    if (freteSelecionado === null) {
      textoAlerta += 'Selecione o <b>Frete</b> <br />';
    }
  
    if (textoAlerta !== '') {

      toast.dismiss();
      toast.warning(<div dangerouslySetInnerHTML={{ __html: textoAlerta }} />);
    } else {

      const storedEmail = localStorage.getItem('merthiolateUserEmail');

      if (storedEmail) {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      } else {

        localStorage.setItem('merthiolateUserEmail', dadosPessoais.email);
      }
  
      const pedido = {
        enderecoEntrega: {
          cep: endereco.cep,
          logradouro: endereco.logradouro,
          numero: endereco.numero,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          destinatario: endereco.destinatario,
          complemento: endereco.complemento,
        },
        freteSelecionado: {
          id: freteSelecionado?.id,
          name: freteSelecionado?.name,
          price: freteSelecionado?.price,
          delivery_time: freteSelecionado?.delivery_time,
        },
        itens: cartItems.map(item => ({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          size: item.size,
          image: item.image,
          color: item.color,
        })),
        valorTotalCompra: totalPedido,
        dataPedido: Timestamp.now()
      };
  
      try {

        const pedidoResponse = await fetch(`${process.env.REACT_APP_API_URL}/asaas/gerarlinkpagamentocartao`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            pedido: pedido
          }),
          mode: 'cors'
        });     
        
        const result = await pedidoResponse.json();

        if(result){
        
          console.log(result);

          if(result['url']){

            window.location.href = result['url'];
          }
        }
      } catch (error) {
        
        toast.error('Erro ao realizar o pedido. Tente novamente.');
      }
    }
  };  

  const formatCurrencyBRL = (value: number) => {

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const valor = e.target.value;
    const valorNumerico = valor.replace(/\D/g, '');
    setCep(valorNumerico);
  };

  const buscarCep = async () => {

    try {

      console.log(cep.length);

      if (cep.length === 8) {

        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();

        if (data.erro) {

          setErro('CEP não encontrado.');
        } else {
          
          setEndereco({
            cep: data.cep,
            logradouro: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            numero: data.numero,
            complemento: '',
            destinatario: dadosPessoais.nome
          });

          setErro('');
        }
        
        const timestamp = new Date().getTime(); 

        const freteResponse = await fetch(`https://api.merthiolate.com.br/frete/calculasuperfrete?cepOrigem=38400500&cepDestino=${data.cep}&quantidadeItens=${totalQuantity}&_=${timestamp}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          mode: 'cors'
      });

      /*  const freteResponse = await fetch(`${process.env.REACT_APP_API_URL}/frete/calculasuperfrete?cepOrigem=${process.env.REACT_APP_CEP_ORIGEM}&cepDestino=${data.cep}&quantidadeItens=${totalQuantity}&_=${timestamp}`, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json'
            }
        }); */

        const freteData = await freteResponse.json();

        if (freteResponse.ok) {
          
          setFretes(freteData);

          const freteMaisBarato = freteData.reduce((prev: Frete, current: Frete) =>
            prev.price < current.price ? prev : current
          );
          selecionarFrete(freteMaisBarato);

        } else {
          setErro('Erro ao calcular frete.');
        }
      }
    } catch (error) {
      setErro('Erro ao buscar CEP ou calcular frete. Tente novamente mais tarde.');
    }
  };

  const selecionarFrete = (frete: Frete) => {

    setFreteSelecionado(frete);
    calculateTotals(cartItems, frete); 
  };

  const increment = (id: string) => {
    const updatedCart = cartItems.map(item =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    setFreteSelecionado(null); 
    setFretes([]); 
    setTotalPedido(totalPrice); 
    calculateTotals(updatedCart);
  };
  
  const decrement = (id: string) => {
    const updatedCart = cartItems.map(item =>
      item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    setFreteSelecionado(null); 
    setFretes([]);
    setTotalPedido(totalPrice); 
    calculateTotals(updatedCart); 
  };
  
  const removeFromCart = (id: string) => {

    const updatedCart = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    calculateTotals(updatedCart); 
  };

  const calculateTotals = (items: CartItem[], frete: Frete | null = freteSelecionado) => {
    const totals = items.reduce(
      (acc, item) => {
        acc.totalPrice += item.price * item.quantity;
        acc.totalQuantity += item.quantity;
        return acc;
      },
      { totalPrice: 0, totalQuantity: 0 }
    );
    
    setTotalPrice(totals.totalPrice);
    setTotalQuantity(totals.totalQuantity);
    setTotalPedido(calcularTotalComFrete(totals.totalPrice, frete));
  };

  const handleDadosPessoais = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log(name);

    let mascaraEspecifica = false;


    if (name === 'cpf') {

      mascaraEspecifica = true;

      let cleanedValue = value.replace(/\D/g, '');
      
      let maskedValue = cleanedValue

      .replace(/\D/g, '') 
      .replace(/(\d{3})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
      
      setDadosPessoais(prevState => ({
        ...prevState,
        [name]: maskedValue
      }));
    }

    if (name === 'telefone') {

      mascaraEspecifica = true;

      let cleanedValue = value.replace(/\D/g, '');
      
      let maskedValue = cleanedValue
      .replace(/\D/g, '') 
      .replace(/(\d{2})(\d)/, '($1) $2') 
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
      
      setDadosPessoais(prevState => ({
        ...prevState,
        [name]: maskedValue
      }));
    }

    if (name === 'cep') {
      
      let cleanedValue = value.replace(/\D/g, ''); 
      
      let maskedValue = cleanedValue
      .replace(/(\d{5})(\d)/, '$1-$2');
      
      setDadosPessoais(prevState => ({
        ...prevState,
        [name]: maskedValue
      }));
    }

    if (name === 'nome') {

      endereco.destinatario = value;
    }

    if(mascaraEspecifica === false){

      setDadosPessoais(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
      setCartItems(parsedCart);
      calculateTotals(parsedCart);
    }
  }, []);

  useEffect(() => {
    updateCartDetails();
  }, [cartItems, updateCartDetails]);

  useEffect(() => {
  }, [dadosPessoais]);

  useEffect(() => {
    if (cep.length === 8) {
      buscarCep();
    }
  }, [cep]);

    return (
        <>    
        <ToastContainer position='top-center' pauseOnHover hideProgressBar={true} closeOnClick />
        <Container className='mt-3'>
        <Row>
        <>
        <Row>
          <div className='text-center'>
            <Link to="/">
              <img src="/assets/logo-merthiolate-1.jpg" alt="Merthiolate" style={{width:'60px'}} />
            </Link>
          </div>
          <h4 className='text-center pb-4'>Resumo do pedido</h4>
        </Row>
        <Row className='pb-3'>
        <Col lg={4}>
        <h5>Itens</h5>
        <hr className='mb-4' />
        <div style={{ border: '1px solid #ccc', padding: '10px 20px', margin: '10px 0' }}>
          {cartItems.length > 0 && (
            cartItems.map((item) => (
            <Row className='mb-1' key={item.id}>
              <Col lg={3} className='trinta-per-mob'>
                        <img src={item.image} alt={item.name} style={{ width: '100%' }} />
                    </Col>
                <Col lg={9} className='text-start setenta-per-mob'>
                    <h6 className='fw-300 mb-1 cemPor float-start'>
                      <b> 
                        <span className='float-start'>{item.name} {item.size}</span> 
                        <span className={`circuloCor2 ms-2 d-block float-start`} style={{ backgroundColor: item.color }}></span>
                      </b>
                    </h6>
                    <h6 className='mb-1 cemPor float-start'>{item.quantity} x <b>{formatCurrencyBRL(item.price)}</b></h6>
                    <h6 className='cemPor float-start'><b>{formatCurrencyBRL(item.price * item.quantity)}</b></h6>
                </Col>
            </Row>
            ))
          )}
        </div>
        </Col>
        <Col lg={5} className='text-start'>
        <h5 className='text-center'>Preencha os dados de entrega</h5>
        <hr className='mb-4' />
        <Row>
        <Col lg={12} className='mb-1'>
        <div className='my-1 px-1 mt-0 float-start cemPor'>
        <h6 className='text-start mt-1 pb-2 fw-300'>Calcule o frete e prazo de entrega</h6>
        <InputGroup className="mb-3 br-1 mt-2">
        <Form.Control
        placeholder="____-___"
        aria-label="____-___"
        aria-describedby="basic-addon2"
        size='lg'
        value={cep}
        id="cep"
        onChange={handleCepChange}
        className='br-1'
        maxLength={8}
        />
        <Button variant="dark" id="button-addon2" className='px-5 br-1' onClick={buscarCep}>
        CALCULAR FRETE
        </Button>
        </InputGroup>
        </div>
        </Col>
      </Row>
      <Row>
      <Col lg={12}>
        <Form.Label>Endereço</Form.Label>
        <Form.Control
          type="text"
          id="logradouro"
          placeholder="Endereço"
          name="logradouro" 
          value={endereco.logradouro}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Bairro</Form.Label>
        <Form.Control
          type="text"
          id="bairro"
          placeholder="Bairro"
          name="bairro"
          value={endereco.bairro}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Cidade</Form.Label>
        <Form.Control
          type="text"
          id="cidade"
          placeholder="Cidade"
          name="cidade" 
          value={endereco.cidade}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Estado</Form.Label>
        <Form.Control
          type="text"
          id="estado"
          placeholder="Estado"
          name="estado" 
          value={endereco.estado}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Número</Form.Label>
        <Form.Control
          type="text"
          id="numero"
          placeholder="Número"
          name="numero" 
          value={endereco.numero}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
      <Col lg={12}>
        <Form.Label>Complemento</Form.Label>
        <Form.Control
          type="text"
          placeholder="Complemento"
          name="complemento"
          value={endereco.complemento}
          onChange={handleEnderecoChange}
          className='br-1 mb-3'
          size='lg'
        />
      </Col>
        </Row>
        </Col>
        <Col lg={3}>
        <h5>Pagamento</h5>
        <hr className='mb-3' />        
      <div>
      {erro && <h6 className='text-danger text-center'>{erro}</h6>}   
      <div>
        {fretes.length > 0 && (
          fretes.map((frete, index) => (
            <div key={index} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
             <div className='cemPor py-2'>
               <img src={frete.company.picture} alt={frete.company.name} style={{ width: '150px', marginRight: '10px' }} />
             </div>
             <div className='d-flex ps-4 py-2'>
                <input 
                  type="radio" 
                  id={`frete-${index}`} 
                  name="frete" 
                  value={frete.id}
                  className='h30-w30'
                  onChange={() => selecionarFrete(frete)} 
                  checked={freteSelecionado ? freteSelecionado.id === frete.id : false}
                />
                <label htmlFor={`frete-${index}`} className='text-start ps-3'>
                  {frete.name} <b>{formatCurrencyBRL(frete.price)}</b> <br />Entrega em <b>{frete.delivery_time}</b> dia(s)
                </label>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    {freteSelecionado ? (
        <>
         <h6 className='text-start py-2'><span className='fw-300'>Endereço de entrega:</span><br /><b>{endereco.logradouro}, {endereco.bairro}, {endereco.cidade} - {endereco.estado}</b></h6>
         <h6 className='text-start'><span className='fw-300'>Quantidade de itens:</span> <b>{totalQuantity}</b></h6>
         <h6 className='text-start'><span className='fw-300'>Valor dos itens:</span> <b>{formatCurrencyBRL(totalPrice)}</b></h6>
         <h6 className='text-start'><span className='fw-300'>Valor do frete:</span> <b>{formatCurrencyBRL(freteSelecionado.price)}</b></h6>
          </>
        ) : (
          <h6 className='text-danger text-center'>Para concluir este pedido selecione um tipo de frete</h6>
        )}
        <h5 className='text-start pb-3 pt-3'><span className='fw-300'>Valor total:</span> <b>{formatCurrencyBRL(totalPedido)}</b></h5>
       
        <Button variant="success" size="lg" className='px-1 br-1 cemPor btn-sm py-2' onClick={gerarPedidoPix}>PAGAR COM PIX &nbsp; <FaPix /> </Button>
       
        <Button variant="success" size="lg" className='px-1 br-1 cemPor btn-sm mt-3 py-2' onClick={gerarPedidoCartao}>PAGAR COM CARTÃO DE CŔEDITO &nbsp; <FaCreditCard /> </Button>
        
       <div className='pb-3 mt-3 text-center'>
       <Link to="/">
            <Button variant="danger" size="lg" className='px-5 br-1 btn-block btn-sm cemPor py-2'>ADICIONAR MAIS PRODUTOS</Button>
        </Link>
        <Link to="/">
            <Button variant="danger" size="lg" className='px-5 br-1 btn-block btn-sm cemPor mt-3 py-2'>VOLTAR PARA LOJA</Button>
        </Link>
        </div>
        </Col>
        </Row>      
        </>
        </Row>
        </Container>
        <FooterComponent />
        </>
    );
};

export default ResumoPage;