import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent';
import CarrinhoComponent from './components/CarrinhoComponent';
import HomePage from './pages/HomePage';
import DetalhePage from './pages/DetalhePage';
import CheckoutPage from './pages/CheckoutPage';
import PixPage from './pages/PixPage';
import ResumoPage from './pages/ResumoPage';
import FiltroPage from './pages/FiltroPage';
import MeuspedidosPage from './pages/MeuspedidosPage';
import MeucadastroPage from './pages/MeucadastroPage';
import CadastroItemPage from './pages/CadastroItemPage';
import SitemapGeneratorPage from './pages/SitemapGeneratorPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const Layout: React.FC<{
  handleShowCart: () => void;
  totalQuantity: number;
  totalPrice: number;
  showCart: boolean;
  handleCloseCart: () => void;
  updateCartDetails: () => void;
  children: React.ReactNode;
}> = ({ handleShowCart, totalQuantity, totalPrice, showCart, handleCloseCart, updateCartDetails, children }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/checkout' && location.pathname !== '/resumo' && location.pathname !== '/pix' && (
        <HeaderComponent
          handleShowCart={handleShowCart}
          totalQuantity={totalQuantity}
          totalPrice={totalPrice}
        />
      )}
      <CarrinhoComponent
        showCart={showCart}
        handleCloseCart={handleCloseCart}
        updateCartDetails={updateCartDetails}
      />
      {children}
    </>
  );
};

function App() {
  const [showCart, setShowCart] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const updateCartDetails = () => {
    const storedCart = JSON.parse(localStorage.getItem('cart') || '[]');
    const totalQuantity = storedCart.reduce((acc: number, item: any) => acc + item.quantity, 0);
    const totalPrice = storedCart.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);
    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  };

  const handleShowCart = () => setShowCart(true);
  const handleCloseCart = () => setShowCart(false);

  const updateCart = () => {
    const storedCart = JSON.parse(localStorage.getItem('cart') || '[]');
    const totalQuantity = storedCart.reduce((acc: number, item: any) => acc + item.quantity, 0);
    const totalPrice = storedCart.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);
    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  };

  useEffect(() => {
    updateCartDetails();
  }, []);

  return (
    <div className="App">
      <Router>
        <Layout
          handleShowCart={handleShowCart}
          totalQuantity={totalQuantity}
          totalPrice={totalPrice}
          showCart={showCart}
          handleCloseCart={handleCloseCart}
          updateCartDetails={updateCartDetails}
        >
          <Routes>
            {/*<Route path="/gerar-sitemap" element={<SitemapGeneratorPage />} /> */}
            <Route path="/" element={<HomePage handleShowCart={handleShowCart} updateCart={updateCart} />} />
            <Route path="/filtro" element={<FiltroPage handleShowCart={handleShowCart} updateCart={updateCart} />} />
            <Route path="/:link" element={<DetalhePage handleShowCart={handleShowCart} updateCartDetails={updateCartDetails} />} />
            <Route path="/meus-pedidos" element={<MeuspedidosPage handleShowCart={handleShowCart} updateCartDetails={updateCartDetails} />} />
            <Route path="/meu-cadastro" element={<MeucadastroPage handleShowCart={handleShowCart} updateCart={updateCart} />} />
            <Route path="/resumo" element={<ResumoPage handleShowCart={handleShowCart} updateCartDetails={updateCart} />} />
            <Route path="/checkout" element={<CheckoutPage handleShowCart={handleShowCart} updateCartDetails={updateCart} />} />
            <Route path="/pix" element={<PixPage handleShowCart={handleShowCart} updateCartDetails={updateCart} />} />
            <Route path="/cadastro-item" element={<CadastroItemPage />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;