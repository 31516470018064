import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CadastroItemPage: React.FC = () => {
  const [hasInserted, setHasInserted] = useState(false);

  const insertData = async () => {
    const itemData = {
      categoria: 'moda-feminina',
      imagem: ['1.jpg', '2.jpg', '3.jpg'],
      link: 'conjunto-calca-legging-costura-trancada-azul-com-blusa-cavada-v-frente-e-costa-roxa-ccsbc01',
      nome: 'Conjunto Calça Legging Costura Trançada Azul com Blusa Cavada V Frente e Costa Roxa CCSBC01',
      subcategoria: 'conjuntos',
      tamanho: ['PP', 'P', 'M', 'G', 'GG'],
      valor: 255,
      cores: [
        {
          codigoCor: '#202C52',
          legendaCor: 'roxo',
          nomeCor: 'Roxo'
        }
      ]
    };

    try {
      const docRef = await addDoc(collection(db, 'item'), itemData);
      toast.success('Dados inseridos no Firestore com sucesso!');
      
      setHasInserted(true); 
    } catch (error) {
      toast.error('Erro ao inserir dados no Firestore.');
    }
  };

  return (
    <div>
      <h1>Página Especial</h1>
      <p>Clique no botão abaixo para inserir dados no Firestore.</p>
      <button onClick={insertData} disabled={hasInserted}>
        {hasInserted ? 'Dados já inseridos' : 'Inserir Dados'}
      </button>
      <ToastContainer />
    </div>
  );
};

export default CadastroItemPage;