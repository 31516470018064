import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Accordion, Badge, Form } from 'react-bootstrap';
import { getFirestore, collection, query, where, getDocs, orderBy, addDoc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';

interface Order {
  status: string;
  date: string;
  orderId: string; 
  address: string;
  paymentMethod: string;
  orderTotal: number;
}

const MeuCadastroPage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {
  
  const [emailCadastro, setEmailCadastro] = useState('');
  const [orders, setOrders] = useState<Order[]>([]);
  const [mensagem, setMensagem] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Novo estado para controlar se o usuário está logado
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [isLogin, setIsLogin] = useState(true); // Estado para alternar entre login e cadastro
  
  // Função para formatar valores em BRL
  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  
  // Função para realizar o login
  const handleLogin = async () => {
    if (!email || !senha) {
      setMensagem('Por favor, preencha o email e a senha.');
      return;
    }
    
    const db = getFirestore();
    const clientesRef = collection(db, 'cliente');
    const q = query(clientesRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const clienteDoc = querySnapshot.docs[0];
      const clienteData = clienteDoc.data();
      
      const senhaCorreta = await bcrypt.compare(senha, clienteData.senha);
      
      if (senhaCorreta) {
        setMensagem('Login realizado com sucesso!');
        localStorage.setItem('merthiolateUserEmail', email); // Armazena o email no localStorage
        setEmailCadastro(email); 
        setIsLoggedIn(true); // Define o estado como logado
        fetchPedidos(email); // Carrega os pedidos
      } else {
        setMensagem('Senha incorreta.');
      }
    } else {
      setMensagem('Cliente não encontrado com este email.');
    }
  };
  
  // Função para realizar o cadastro
  const handleCadastro = async () => {
    if (!email || !senha) {
      setMensagem('Por favor, preencha o email e a senha.');
      return;
    }
    
    const db = getFirestore();
    const clientesRef = collection(db, 'cliente');
    const q = query(clientesRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      setMensagem('Este email já está cadastrado.');
      return;
    }
    
    const saltRounds = 10;
    const senhaHash = await bcrypt.hash(senha, saltRounds);
    
    await addDoc(clientesRef, {
      email: email,
      senha: senhaHash,
    });
    
    setMensagem('Cadastro realizado com sucesso!');
    localStorage.setItem('merthiolateUserEmail', email); // Armazena o email no localStorage
    setIsLoggedIn(true); // Define o estado como logado
    fetchPedidos(email); // Carrega os pedidos
    setEmail('');
    setSenha('');
  };
  
  // Função para carregar os pedidos
  const fetchPedidos = async (email: string) => {
    const db = getFirestore();
    const pedidosRef = collection(db, 'venda');
    const q = query(
      pedidosRef,
      where('cliente.email', '==', email),
      orderBy('payment.dueDate', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const fetchedOrders: Order[] = querySnapshot.docs.map((doc) => {
        const pedidoData = doc.data();
        return {
          date: new Date(pedidoData.payment.dueDate * 1000).toLocaleDateString('pt-BR'),
          orderId: doc.id,
          status: pedidoData.statusPedido,
          address: `${pedidoData.cliente.address}, ${pedidoData.cliente.province}, ${pedidoData.cliente.cityName} / ${pedidoData.cliente.postalCode}`,
          paymentMethod: pedidoData.payment.billingType,
          orderTotal: pedidoData.payment.value,
        };
      });
      
      setOrders(fetchedOrders);
      setMensagem(null);
    } else {
      setMensagem('Nenhum pedido encontrado.');
    }
  };
  
  // Verificar se o usuário já está logado
  useEffect(() => {
    const storedEmail = localStorage.getItem('merthiolateUserEmail');
    if (storedEmail) {
      setIsLoggedIn(true); // Define como logado
      setEmailCadastro(storedEmail);
      fetchPedidos(storedEmail); // Carrega os pedidos ao carregar a página
    }
  }, []);
  
  return (
    <Container className="mt-5">
    <Row>
    <Col lg={12}>
    {!isLoggedIn ? (
      <>
      {/* Formulário de login e cadastro */}
      <h4 className="text-center pb-2">{isLogin ? 'Login' : 'Cadastro'}</h4>
      
      <div className='text-start'>
      {isLogin ? (
        <>
        <Form.Group className="mb-3">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Digite seu email"
        size='lg'
        className='br-1'
        />
        </Form.Group>
        
        <Form.Group className="mb-3">
        <Form.Label>Senha</Form.Label>
        <Form.Control
        type="password"
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        placeholder="Digite sua senha"
        size='lg'
        className='br-1'
        />
        </Form.Group>
        
        <Button variant="primary" className="w-100 br-1" onClick={handleLogin}>
        ACESSAR
        </Button>
        
        {mensagem && <p className="text-center mt-3">{mensagem}</p>}
        
        <div className="text-center mt-3">
        <Button variant="link" onClick={() => setIsLogin(false)}>
        Não tem uma conta? Cadastre-se
        </Button>
        </div>
        </>
      ) : (
        <>
        <Form.Group className="mb-3">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Digite seu email"
        size='lg'
        className='br-1'
        />
        </Form.Group>
        
        <Form.Group className="mb-3">
        <Form.Label>Senha</Form.Label>
        <Form.Control
        type="password"
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        placeholder="Digite sua senha"
        size='lg'
        className='br-1'
        />
        </Form.Group>
        
        <Button variant="primary" className="w-100 br-1" onClick={handleCadastro}>
        CADASTRAR
        </Button>
        
        {mensagem && <p className="text-center mt-3">{mensagem}</p>}
        
        <div className="text-center mt-3">
        <Button variant="link" onClick={() => setIsLogin(true)}>
        Já tem uma conta? Faça login
        </Button>
        </div>
        </>
      )}
      </div>
      </>
    ) : (
      <h5 className="text-center">Bem-vindo, {emailCadastro}</h5>
    )}
    </Col>
    
    <Col lg={12}>
    <h5>Meus pedidos</h5>
    <hr className="mb-3" />
    <Row>
    <Accordion defaultActiveKey={['0']}>
    {orders.map((order, index) => (
      <Accordion.Item eventKey={String(index)} key={order.orderId}>
      <Accordion.Header>Data do pedido: &nbsp; <small>{order.date}</small></Accordion.Header>
      <Accordion.Body>
      <Row className="mb-0">
      <Col lg={6} className="text-start">
      <h6>Status pedido: <Badge bg={order.status === 'Aguardando pagamento' ? 'warning' : 'success'}>{order.status}</Badge></h6>
      <h6>Código do pedido: <b>{order.orderId}</b></h6>
      <h6>Endereço de entrega:</h6>
      <p><small><b>{order.address}</b></small></p>
      <h6>Forma de pagamento: <b>{order.paymentMethod}</b></h6>
      <h6>Valor total do pedido: <b>{formatCurrencyBRL(order.orderTotal)}</b></h6>
      </Col>
      </Row>
      </Accordion.Body>
      </Accordion.Item>
    ))}
    </Accordion>
    </Row>
    </Col>
    </Row>
    </Container>
  );
};

export default MeuCadastroPage;