import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation } from 'react-router-dom'; // Importando useLocation
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-awesome-slider/dist/styles.css';

interface Frete {
  id: number;
  name: string;
  price: number;
  delivery_time: number;
  currency: string;
  company: {
    name: string;
    picture: string;
  };
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  size: string;
  image: string;
  color: string;
}

interface AlertProps {
  id: number;
  type: 'info' | 'success' | 'warning' | 'danger';
  message: string;
}

const PixPage: React.FC<{ handleShowCart: () => void; updateCartDetails: () => void }> = ({ handleShowCart, updateCartDetails }) => {

  const [encodedImage, setEncodedImage] = useState('');
  const [payload, setPayload] = useState('');

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const location = useLocation();

  const getParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const idPedido = searchParams.get('idPedido');
    const gateway = searchParams.get('gateway');
    return { idPedido, gateway };
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(payload).then(() => {
      toast.success('Código Pix copiado!');
    }).catch(err => {
      console.error('Erro ao copiar o texto: ', err);
    });
  };

  const gerarQrCode = async (idPedido: string | null, gateway: string | null) => {

    if (gateway === 'asaas' && idPedido) {
        try {
            
           
            const pedidoResponse = await fetch(`${process.env.REACT_APP_API_URL}/asaas/gerarqrcodepix`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    idPedido: idPedido,
                }),
                mode: 'cors'
            });

            if (pedidoResponse.ok) {
                const result = await pedidoResponse.json();

                if (result) {
                    setEncodedImage(result['encodedImage']);
                    setPayload(result['payload']);
                }
            } else {
                console.error('Erro ao gerar QR Code: ', pedidoResponse.status);
            }
        } catch (error) {
            console.error('Erro ao fazer a requisição: ', error);
        }
    } else {
        console.warn('ID do Pedido ou Gateway inválido');
    }
};

  useEffect(() => {
    const { idPedido, gateway } = getParams();
    gerarQrCode(idPedido, gateway);
  }, [location.search]);

  return (
    <>
     <>
      <ToastContainer position='top-center' pauseOnHover hideProgressBar={true} closeOnClick />
      <Container className='mt-3'>
        <Row>
          <div className='text-center'>
            <Link to="/">
              <img src="/assets/logo-merthiolate-1.jpg" alt="Merthiolate" style={{width:'60px'}} />
            </Link>
          </div>
          <h5 className='text-center pb-2'>Acesse seu APP de pagamentos e faça a leitura do QR Code abaixo para efetuar o pagamento de forma rápida e segura.</h5>
        </Row>
        <Row className='pb-3'>
        <Col lg={8}>
            <img src={`data:image/png;base64,${encodedImage}`} />
        </Col>
        <Col lg={4}>
       <Card className='border-grey mb-3 br-1 mt-4'>
          <Card.Body className='p-1'>
              <div className='py-3 mt-2 mb-1 px-3'>
                <h6>Código Pix copia e cola</h6>
                <p className='pb-1'><small>{payload}</small></p>
                <Button variant="primary" size="lg" className='px-1 br-1 cemPor' onClick={handleCopy}>COPIAR</Button>
              </div>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Container>
      </>
    </>
  );
};

export default PixPage;